// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { isBrowser, isWebWorker } from '@aws-amplify/core/internals/utils';
import IndexedDBAdapter from '../IndexedDBAdapter';
// eslint-disable-next-line import/no-named-as-default
import AsyncStorageAdapter from '../AsyncStorageAdapter';
const getDefaultAdapter = () => {
    if ((isBrowser && window.indexedDB) || (isWebWorker() && self.indexedDB)) {
        return IndexedDBAdapter;
    }
    return AsyncStorageAdapter;
};
export default getDefaultAdapter;
